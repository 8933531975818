@import 'src/styles/bootstrap';

#InsuranceForm {
  margin: auto;
  overflow: hidden;
  padding: 24px;
  border: 2px solid #EEE;
  //box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;

  h2 {
    margin-bottom: 0px;
  }

  h5 {
    font-size: 1.2rem;
    color: map-get($theme-colors, 'primary');
    margin: auto;
    margin-bottom: 5px;

    + p{
      margin: auto;
      margin-bottom: 40px;
      white-space: pre-wrap;
    }
  }

  .privacy {
    text-decoration: underline;
  }
}

.steps-amount {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.wizard-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wizard-btn-row-single {
  display: flex;
  justify-content: right;
  align-items: center;
}

.wizard-btn-row {
  display: flex;
  justify-content: end;
  gap: 12px;
}


.animated {
  animation-duration: .3s !important;
}

.file-upload {
  margin-bottom: 20px;
}

#FileSection {
  h5 {
    margin-bottom: 20px;
  }
}
