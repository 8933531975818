@import 'src/styles/mixins';

.page-section {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 60px 0;

  h2 {
    color: #37517e;
    font-weight: 300;
    margin: auto;
    margin-bottom: 40px;
  }

  p {
    color: #777777;
    margin-bottom: 40px;
  }

  @include for-tablet-portrait-down {
    h2 {
      margin-bottom: 20px;
    }
  }
}
