@import "src/styles/bootstrap";

.partner-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;

  img {
    opacity: .6;
    filter: grayscale(100%);
    transition: filter .3s,opacity .3s;
    transform: scale(.7);
    &:hover {
      opacity: 1;
      -webkit-filter: grayscale(0);
      filter: grayscale(0)
    }
  }
}

.logos__grid {
  overflow: hidden;
}

#Partners {
  min-height: auto;
  background-color: #EDF7FF;
}