#basic-nav-dropdown {
  border: 1px solid #D3D3D3;
  border-radius: 50px;
  margin-right: 8px;
  color: #777;

  &::after {
    border-bottom-color: #D3D3D3;
    border-top-color: #D3D3D3;
  }
}