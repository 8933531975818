// Override default variables before the import
$body-bg: #000;

$primary: #459ADD;

$btn-border-radius: 8px !important;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

.form-control {
  background: white !important;
}

.btn {
  border-radius: 50px;
  padding: 8px 15px;

    &.btn-primary {
        color: white;
    }
}

.btn-link {
  text-decoration: none !important;
  color: #009dff !important;
  outline: none !important;
  border: none !important;
  background: none !important;
  box-shadow: none !important;

  &:hover, &:active, &:focus {
    outline: none !important;
    border: none !important;
    background: none !important;
    box-shadow: none !important;
    color: #33b1ff !important;
  }
}
