// Import Bootstrap and its default variables
@import 'styles/bootstrap';
@import "src/styles/mixins";

body {
  background: transparent !important;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}



#MainLogo {
  height: 35px;
}

.navbar > .container {
  justify-content: space-between;
}

.collapse.show {
  .nav-item.dropdown {
    margin: 8px 0;
  }
}

.header-moved {
  background-color: rgba(40, 58, 90, 0.9) !important;

  .navbar-toggler {
    background: #FAFAFA !important;
  }
}

.main-nav {
  padding: 15px 0 !important;
  border-bottom: 2px solid #EEE;
  background-color: rgba(255, 255, 255, 0.9);

  .dropdown-menu {
    background: #FAFAFA !important;
    border-color: #DDD;
  }

  .nav-link {
    font-size: 15px !important;
    padding: 0.5rem 1rem !important;
    color: #777;
  }
}

.main-nav.header-moved {
  .nav-link {
    color: white !important;
  }
}

.section-title {
  margin-bottom: 3rem !important;
}
