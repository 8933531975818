@import "src/styles/mixins";

#ContactForm {
  margin: auto;
  background-color: white;
  overflow: hidden;
  padding: 40px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  max-width: 800px;
}

#ContactSection {
  background-color: #37517e;
  min-height: calc(100vh - 50px);

  h2 {
    color: white;
  }

  p {
    color: white;
  }

  @include for-tablet-portrait-down {
    .btn-primary {
      display: block;
      width: 100%;
    }
  }

  .contact-action {
    text-align: right;
  }
}

#Footer {
  padding-top: 30px;
  display: flex;
  align-items: end;
  justify-content: center;
  color: white;
}
