@import "src/styles/bootstrap";

#AboutSection {
  background-color: #EDF7FF;
}

.features {
  .feature-item {
    margin-bottom: 40px;
    display: flex;
    align-items: start;
    gap: 12px;
    flex-direction: column;
  }

  h4 {
    font-size: 1.2rem;
    margin: 0;
  }

  .feature-icon {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
  }

  .feature-title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }

  .feature-description {
    margin-left: 32px;
  }
}
